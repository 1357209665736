<template>
  <div>
  </div>
</template>
<script>

export default {

  created() {
    window.location.href = "https://shop.indecent-behavior.com";
  },

};
</script>